export default {
  data() {
    return {
      id: "",
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      texts: "",
      screenWidth: 0,
      heightTable: 0,
      bLoading: false,
      sPermits: "",
      aPermits: [
        { name: "Permiso 1", value: 1 },
        { name: "Permiso 2", value: 2 },
        { name: "Permiso 3", value: 3 },
        { name: "Permiso 4", value: 4 },
      ],
      selected: "A",
      optionGroups: {
        First: [
          { text: "One", value: "A" },
          { text: "Two", value: "B" },
        ],
        Second: [{ text: "Three", value: "C" }],
      },
      itemsPermits: [],
      bModifyAdmin: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.subadminTexts[this.selectLanguage];
  },
  methods: {
    getPermitsDropdown() {
      let params = {};
      if (this.itemsPermits.length > 0) {
        params = {
          aExceptionId: this.itemsPermits
            .map((e) => {
              return e.sPermissionId;
            })
            .reverse()
            .join(","),
        };
      }
      DB.get(`${URI}/api/v1/${this.selectLanguage}/permissions`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params,
      })
        .then((response) => {
          this.aPermits = response.data.results;
          this.sPermits = "";
        })
        .catch((error) => {
          console.log(error);
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    getPermitsById() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/permissions/${this.id}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          // iPageNumber: 1,
        },
      })
        .then((response) => {
          this.itemsPermits = response.data.results;
          this.getPermitsDropdown();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setPermit() {
      const arr = {
        sPermissionId: this.sPermits,
        sPermissionName: this.aPermits.find(
          (s) => s.sPermissionId === this.sPermits
        ).sPermissionName,
      };
      this.itemsPermits.push(arr);
      this.getPermitsDropdown();
      this.clearItemsPermit();
    },
    deleteItemPermit(e) {
      this.itemsPermits.splice(e, 1);
      this.getPermitsDropdown();
      this.clearItemsPermit();
    },
    clearItemsPermit() {
      this.sPermits = "";
      this.$refs.cmbPermits.blur();
      this.$refs.cmbPermits.reset();
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    deleteItem() {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.deleteAdmin.title,
        descipcion: this.texts.deleteAdmin.description,
        note: this.texts.deleteAdmin.note,
        chkLabel: "",
        api: `${URI}/api/v1/${this.selectLanguage}/administrators/${this.arrDetailSubadmin.sAdministratorId}`,
        return: false,
        dobleConfirm: false,
      });
    },
    editSubadmin() {
      this.bLoading = true;

      const params = {
        sName: this.name,
        sLastname: this.lastname,
        sEmail: this.email,
        sPhoneNumber: this.phoneNumber,
        aPermissionsId: this.itemsPermits.map((e) => {
          return e.sPermissionId;
        }),
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.id}`,
        params,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    close() {
      this.$store.commit("setDialogDetailSubadmin", {
        active: false,
        arr: [],
      });
      this.bModifyAdmin = false;
    },
    emitClose() {
      this.$store.commit("setDialogDetailSubadmin", {
        active: false,
        arr: [],
      });
      this.bModifyAdmin = false;
    },
    handleResize() {
      this.heightTable = window.innerHeight - 550;
      if (window.innerWidth >= 960) {
        this.screenWidth = 55;
      } else {
        this.screenWidth = 100;
      }
    },
    dialogAddPermits() {
      this.$store.commit("setDialogAddPermits", true);
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogDetailSubadmin() {
      return this.$store.state.dialogDetailSubadmin;
    },
    arrDetailSubadmin() {
      return this.$store.state.arrDetailSubadmin;
    },
    validateForm() {
      return (
        this.name !== "" &&
        this.lastname !== "" &&
        this.email !== "" &&
        this.phoneNumber !== "" &&
        this.name !== null &&
        this.lastname !== null &&
        this.email !== null &&
        this.phoneNumber !== null &&
        this.itemsPermits.length
      );
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.subadminTexts[this.selectLanguage];
      }
    },
    dialogDetailSubadmin: function() {
      if (this.dialogDetailSubadmin) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.subadminTexts[this.selectLanguage];
        this.id = this.arrDetailSubadmin.sAdministratorId;
        this.getPermitsById();
        this.name = this.arrDetailSubadmin.sName;
        this.lastname = this.arrDetailSubadmin.sLastname;
        this.email = this.arrDetailSubadmin.sEmail;
        this.phoneNumber = this.arrDetailSubadmin.sPhoneNumber;
      }
    },
  },
};
